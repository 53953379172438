import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import { ColumnDescriptorProps } from './ListTable';
import { SortDirection } from '../utils/appsUtils';

interface TableHeaderProps<T> {
    columnDescriptor: ColumnDescriptorProps<T>;
    sortBy?: keyof T;
    sortDir: SortDirection;
    handleSortChange?: (event: React.MouseEvent<HTMLTableCellElement>) => void;
}

export const TableHeader = <T, >({
                                     columnDescriptor,
                                     sortBy = 'something' as keyof T,
                                     sortDir = SortDirection.ASCENDING,
                                     handleSortChange,
                                 }: TableHeaderProps<T>) => {
    const {id, className, format, formatLabel, label, sortable} = columnDescriptor;

    const renderSortArrow = () => {
        if (sortable) {
            if (sortBy === id) {
                return <SortArrows direction={sortDir}/>;
            }
            return <SortArrows/>;
        }
    };

    const toLabel = () => {
        if (formatLabel) {
            return <span>{String(formatLabel(id))}</span>;
        }

        if (label) {
            return <FormattedMessage id={label} />;
        }
        return <FormattedMessage id={`intl-msg:field.unknown.${id}`} />;
    };

    if (columnDescriptor.hidden) {
        return null;
    }

    const labelText = !!label ? toLabel() : '';
    const sorting = renderSortArrow();
    const headerClassNames = clsx('user-select-none', {'sort-column': !!sorting}, className);
    return (
        <th key={id}
            className={headerClassNames}
            data-field={id}
            onClick={(event) => {
                handleSortChange?.(event);
            }}
            data-sortby={id}>
            <span>
                {sorting}
                {labelText}
            </span>
        </th>
    );
};
