import { Profile } from 'oidc-client';
import { UserProfile } from './loginSlice';

const stripSnakeProps = (obj: Record<string, any>) => {
    const keysToOmit = ['family_name', 'given_name'];
    return Object.keys(obj)
        .filter((key) => !keysToOmit.includes(key)) // Exclude unwanted keys
        .reduce((result, key) => {
            result[key] = obj[key];
            return result;
        }, {} as Record<string, any>);
};


export const mapUserProfile = (profile: Profile): UserProfile => ({
    sub: profile.sub,
    azp: profile.azp,
    givenName: profile.given_name,
    familyName: profile.family_name,
    name: profile.name,
    locale: profile.locale,
    email: profile.email,
    ...stripSnakeProps(profile),
});