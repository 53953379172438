import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import {config} from '../config';
import {accessToken} from '../configuration/tokenHandling/accessToken';
import {AccessToken} from '../configuration/tokenHandling/tokenSlice';
import {SigningFilter, SigningPage} from '../features/app/context/device-signing/signing.types';
import {
    ApiDeviceSigningDataModelCM4,
    ApiDeviceSigningDataModelList,
    ApiDeviceSigningDataModelListCM4,
    ApiDeviceSigningDataModelTBM3,
    DeviceMonitor,
    DeviceSigningCertificateInfo,
    mapDeviceSigningApiResponse,
    mapDeviceSigningApiResponseCM4,
    mapDeviceSigningDetailsApiResponseCM4,
    NotificationInfo
} from '../features/app/context/device-sidebar/device-signing.types';

const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

export const deviceSigningStateApi = createApi({
    reducerPath: 'deviceSigning',
    tagTypes: ['DeviceSigningState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchDeviceSigningStates: builder.query<ApiDeviceSigningDataModelTBM3[], {
            signingFilter: SigningFilter;
            signingPage: SigningPage;
        }>({
            query: ({signingFilter, signingPage}) => ({
                url: `/devices/devicedatamodel/${signingFilter.signingState}?${qs.stringify(
                    {
                        devicetype: signingFilter.deviceType,
                        starttimespan: signingFilter.startDateTime,
                        endtimespan: signingFilter.endDateTime,
                        page: signingPage.page,
                        size: signingPage.size,
                    },
                    {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
            }),
            keepUnusedDataFor: 1,
            providesTags: ['DeviceSigningState'],
            transformResponse: (response: ApiDeviceSigningDataModelList) => mapDeviceSigningApiResponse(response)
        }),
        fetchDeviceSigningDetailsStates: builder.query<ApiDeviceSigningDataModelTBM3[], {
            serialNumber: string | undefined;
            identifier: string;
        }>({
            query: ({serialNumber, identifier}) => ({
                url: `/devices/${serialNumber}/ddm?${qs.stringify(
                    {
                        identifier
                    },
                    {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceSigningState'],
        }),
        fetchCertificatesStates: builder.query<DeviceSigningCertificateInfo[], {
            serialNumber: string | undefined;
        }>({
            query: ({serialNumber}) => ({
                url: `/devices/${serialNumber}/certificates`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceSigningState'],
        }),
        fetchDeviceSigningInfo: builder.query<ApiDeviceSigningDataModelTBM3[], {
            serialNumber: string;
            identifier: string | undefined;
        }>({
            query: ({serialNumber, identifier}) => ({
                url: `/devices/${serialNumber}?${qs.stringify(
                    {
                        identifier
                    },
                    {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceSigningState'],
        }),
    }),
});

export const deviceCertificateCM4Api = createApi({
    reducerPath: 'deviceCertificateCM4',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_SERVICE_CM4,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchCertificate: builder.query<string, {
            certSerialNumber: any;
        }>({
            query: ({certSerialNumber}) => ({
                url: `/devices/certificates/${certSerialNumber}/string`,
                responseHandler: 'text'
            }),
            keepUnusedDataFor: 0,
        }),
    })
});

export const deviceCertificateTBMApi = createApi({
    reducerPath: 'deviceCertificateTBM',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchCertificate: builder.query<string, {
            certSerialNumber: any;
        }>({
            query: ({certSerialNumber}) => ({
                url: `/devices/certificates/${certSerialNumber}/string`,
                responseHandler: 'text'
            }),
            keepUnusedDataFor: 0,
        }),
    })
});

export const deviceSigningStateCM4Api = createApi({
    reducerPath: 'deviceSigningCm4',
    tagTypes: ['DeviceSigningState', 'DeviceCertificate'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_SERVICE_CM4,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchDeviceSigningStates: builder.query<ApiDeviceSigningDataModelCM4[], {
            signingFilter: SigningFilter;
            signingPage: SigningPage;
        }>({
            query: ({signingFilter, signingPage}) => ({
                url: `/devices/devicedatamodel/${signingFilter.signingState}?${qs.stringify(
                    {
                        devicetype: signingFilter.deviceType,
                        starttimespan: signingFilter.startDateTime,
                        endtimespan: signingFilter.endDateTime,
                        page: signingPage.page,
                        size: signingPage.size,
                    },
                    {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
            }),
            keepUnusedDataFor: 1,
            providesTags: ['DeviceSigningState'],
            transformResponse: (response: ApiDeviceSigningDataModelListCM4) => mapDeviceSigningApiResponseCM4(response)
        }),
        fetchDeviceSigningDetailsStates: builder.query<ApiDeviceSigningDataModelCM4, {
            serialNumber: string | undefined;
            identifier: string;
        }>({
            query: ({serialNumber, identifier}) => ({
                url: `/devices/${serialNumber}/ddm?${qs.stringify(
                    {
                        identifier
                    },
                    {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceSigningState'],
            transformResponse: (response: ApiDeviceSigningDataModelCM4) =>
                mapDeviceSigningDetailsApiResponseCM4(response)
        }),
        fetchDeviceInfo: builder.query<ApiDeviceSigningDataModelCM4, {
            serialNumber: string;
            identifier: string | undefined;
        }>({
            query: ({serialNumber, identifier}) => ({
                url: `/devices/${serialNumber}?${qs.stringify(
                    {
                        identifier
                    },
                    {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: ApiDeviceSigningDataModelCM4) =>
                mapDeviceSigningDetailsApiResponseCM4(response)
        }),
        fetchCertificatesStates: builder.query<DeviceSigningCertificateInfo[], {
            serialNumber: string | undefined;
        }>({
            query: ({serialNumber}) => ({
                url: `/devices/${serialNumber}/certificates`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceSigningState'],
        })
    }),
});

export const deviceSigningInitMonitorStateApi = createApi({
    reducerPath: 'deviceSigningInitMonitor',
    tagTypes: ['DeviceSigningInitMonitorState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_INIT_MONITOR_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchDeviceSigningInitMonitorDetailsStates: builder.query<DeviceMonitor, {
            imei: string | undefined;
        }>({
            query: ({imei}) => ({
                url: `/devices/imei/${imei}`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceSigningInitMonitorState'],
        }),
        fetchDeviceMonitorInfo: builder.query<DeviceMonitor, {
            searchPrefix: string;
            value: string;
        }>({
            query: ({searchPrefix, value}) => ({
                url: `/devices/${searchPrefix}/${value}`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceSigningInitMonitorState']
        })
    }),
});

export const deviceSigningNotificationStateApi = createApi({
    reducerPath: 'deviceSigningNotification',
    tagTypes: ['DeviceSigningNotificationState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.SYSTEM_STATE_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchDeviceSigningNotificationDetailsStates: builder.query<NotificationInfo, {
            serialNumber: string | undefined;
        }>({
            query: ({serialNumber}) => ({
                url: `/devices/${serialNumber}/info`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceSigningNotificationState'],
        }),
    }),
});

export const {
    useLazyFetchDeviceSigningStatesQuery,
    useLazyFetchDeviceSigningDetailsStatesQuery,
    useFetchDeviceSigningDetailsStatesQuery,
    useLazyFetchCertificatesStatesQuery,
    useLazyFetchDeviceSigningInfoQuery
} = deviceSigningStateApi;
export const {
    useLazyFetchDeviceSigningStatesQuery: useLazyFetchDeviceSigningStatesCM4Query,
    useLazyFetchDeviceSigningDetailsStatesQuery: useLazyFetchDeviceSigningDetailsStatesCM4Query,
    useLazyFetchCertificatesStatesQuery: useLazyFetchCertificatesStatesCM4Query,
    useLazyFetchDeviceInfoQuery: useLazyFetchDeviceCM4Query
} = deviceSigningStateCM4Api;

export const {
    useLazyFetchCertificateQuery: useLazyFetchCertificateCM4Query
} = deviceCertificateCM4Api;

export const {
    useLazyFetchCertificateQuery: useLazyFetchCertificateTBMQuery
} = deviceCertificateTBMApi;


export const {
    useLazyFetchDeviceSigningInitMonitorDetailsStatesQuery,
    useFetchDeviceSigningInitMonitorDetailsStatesQuery,
    useLazyFetchDeviceMonitorInfoQuery,
} = deviceSigningInitMonitorStateApi;
export const {
    useLazyFetchDeviceSigningNotificationDetailsStatesQuery,
    useFetchDeviceSigningNotificationDetailsStatesQuery
} = deviceSigningNotificationStateApi;
