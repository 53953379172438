import React, {useEffect, useRef} from 'react';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import {useAppSelector} from '../../../../../configuration/setup/hooks';
import {useDispatch} from 'react-redux';
import {getSelectedDeviceSidebar, setSelectedDeviceSidebar} from '../sidebarSlice';

export const DeviceStateContainer = ({children}: any) => {
    const dispatch = useDispatch();
    const selectedDeviceSidebar = useAppSelector(getSelectedDeviceSidebar);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
    }, []);

    const handleClickOutside = (evt: any) => {
        const selectedDeviceDetailsSidebar = evt.target.closest('.deviceDetailsSidebar');
        if (selectedDeviceDetailsSidebar === null) {
            dispatch(setSelectedDeviceSidebar(undefined));
        }
    };

    return (
        <Sidebar
            className="deviceDetailsSidebar"
            fly
            width={600}
            closed={!selectedDeviceSidebar}
            onClose={() => dispatch(setSelectedDeviceSidebar(undefined))}
        >
            {children}
        </Sidebar>);
};
