import React from 'react';
import {FormattedTime} from 'react-intl';
import {copyToClipboard} from '../../../../../components/utils/appsUtils';
import {FieldOrNoData, NoData, YesOrNo} from '../../../../../components/PropertyTableFieldDefinitions';
import {ColumnDescriptorProps} from '../../../../../components/table/ListTable';
import {
    AllDeviceSigningInfo,
    ApiDeviceSigningDataModelCM4,
    ApiDeviceSigningDataModelTBM3,
    isLatamDevice,
    SigningStatus
} from '../device-signing.types';
import {DEVICE_TYPE} from '../device-state.types';
import {SigningSearchResult} from '../../device-signing/components/SigningSearch';

export const detailsRowDescriptors: ColumnDescriptorProps<AllDeviceSigningInfo>[] = [
    {
        id: 'prod',
        field: 'prod',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.devDevice',
        toHide: (field: any, item: AllDeviceSigningInfo | undefined) => {
            if (item && item.signing && 'prod' in item.signing) {
                return (item?.signing as ApiDeviceSigningDataModelCM4).prod;
            } else {
                return true;
            }
        },
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <YesOrNo field={!(item?.signing as ApiDeviceSigningDataModelCM4)?.prod} />
        ),
    },
    {
        id: 'serialNumber',
        field: 'serialNumber',
        label: 'intl-msg:coreconAdminWeb.hwSerial',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => [
            <FieldOrNoData key="fieldOrNoData" field={item?.signing?.serialNumber} />,
            field && (
                <span
                    key="123"
                    className="rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5"
                    onClick={(event) => {
                        event.stopPropagation();
                        copyToClipboard(field, true);
                    }}
                />
            ),
        ],
    },
    {
        id: 'iccid',
        field: 'iccid',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.iccid',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData
                key="fieldOrNoData"
                field={
                    item?.signing?.deviceType === DEVICE_TYPE.CM4
                        ? (item.signing as ApiDeviceSigningDataModelCM4)?.iccid
                        : (item?.signing as ApiDeviceSigningDataModelTBM3)?.sreData?.iccid ||
                        (item?.signing as ApiDeviceSigningDataModelTBM3)?.certData?.iccid
                }
            />
        ),
    },
    {
        id: 'imsi',
        field: 'imsi',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.imsi',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData
                key="fieldOrNoData"
                field={
                    item?.signing?.deviceType === DEVICE_TYPE.CM4
                        ? (item.signing as ApiDeviceSigningDataModelCM4)?.imsi
                        : (item?.signing as ApiDeviceSigningDataModelTBM3)?.sreData?.imsi ||
                        (item?.signing as ApiDeviceSigningDataModelTBM3)?.certData?.imsi
                }
            />
        ),
    },
    {
        id: 'imei',
        field: 'imei',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.imei',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData
                key="fieldOrNoData"
                field={
                    item?.signing?.deviceType === DEVICE_TYPE.CM4
                        ? (item.signing as ApiDeviceSigningDataModelCM4)?.imei
                        : (item?.signing as ApiDeviceSigningDataModelTBM3)?.sreData?.tbm3_imei ||
                        (item?.signing as ApiDeviceSigningDataModelTBM3)?.certData?.imei
                }
            />
        ),
    },
    {
        id: 'vin7',
        field: 'vin7',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.shortVin',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData field={item?.monitor?.vin7} />
        ),
    },
    {
        id: 'vin17',
        field: 'vin17',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.nasysVin',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData field={item?.monitor?.vin17} />
        ),
    },
    {
        id: 'created',
        field: 'created',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.createdNasys',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData
                field={
                    item && item.monitor ? (
                        <FormattedTime
                            value={item?.monitor?.created}
                            year="numeric"
                            month="2-digit"
                            day="2-digit"
                        />
                    ) : null
                }
            />
        ),
    },
    {
        id: 'initSuccessful',
        field: 'initSuccessful',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.initSuccessful',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <YesOrNo
                field={
                    item?.signing?.signingState.signingStatus === SigningStatus.FETCHED_BY_DEVICE
                }
            />
        ),
    },
    {
        id: 'timestamp',
        field: 'timestamp',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.signingDate',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData
                field={
                    item?.signing?.firstSigningDate ? (
                        <FormattedTime
                            value={item?.signing?.firstSigningDate}
                            year="numeric"
                            month="2-digit"
                            day="2-digit"
                        />
                    ) : null
                }
            />
        ),
    },
    {
        id: 'signingStatus',
        field: 'signingStatus',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.signingStatus',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData field={item?.signing?.signingState?.signingStatus} />
        ),
    },
    {
        id: 'errorMessage',
        field: 'errorMessage',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.errorMessage',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <FieldOrNoData field={item?.signing?.signingState?.errorStatus} />
        ),
    },
    {
        id: 'replaced',
        field: 'replaced',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.replaced',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => (
            <YesOrNo field={item?.monitor?.replaced} />
        ),
    },
    {
        id: 'latamDevice',
        field: 'latamDevice',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.latamDevice',
        format: (field: any, item: AllDeviceSigningInfo | undefined) => {
            if (item === undefined || item.signing === undefined) {
                return <NoData />;
            }
            if (item.signing.deviceType === DEVICE_TYPE.CM4) {
                return (
                    <YesOrNo
                        field={isLatamDevice(
                            (item.signing as ApiDeviceSigningDataModelCM4).iccid
                        )}
                    />
                );
            }

            if (
                (item?.signing as ApiDeviceSigningDataModelTBM3)?.sreData?.iccid !== undefined
            ) {
                return (
                    <YesOrNo
                        field={isLatamDevice(
                            (item.signing as ApiDeviceSigningDataModelTBM3).sreData.iccid
                        )}
                    />
                );
            }

            if (
                (item?.signing as ApiDeviceSigningDataModelTBM3)?.certData?.iccid !== undefined
            ) {
                return (
                    <YesOrNo
                        field={isLatamDevice(
                            (item.signing as ApiDeviceSigningDataModelTBM3).certData.iccid
                        )}
                    />
                );
            }

            return <NoData />;
        },
    },
].filter(Boolean);

export const vcmDetailsRowDescriptors: ColumnDescriptorProps<ApiDeviceSigningDataModelTBM3>[] = [
    {
        id: 'serialNumber',
        field: 'serialNumber',
        label: 'intl-msg:coreconAdminWeb.hwSerial',
        format: (field: any, item: ApiDeviceSigningDataModelTBM3 | undefined) => [
            <FieldOrNoData key="fieldOrNoData" field={item?.serialNumber} />,
            field && (
                <span
                    key="123"
                    className="rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5"
                    onClick={(event) => {
                        event.stopPropagation();
                        copyToClipboard(field, true);
                    }}
                />
            ),
        ],
    },
    {
        id: 'created',
        field: 'created',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.createdNasys',
        format: (field: any, item: ApiDeviceSigningDataModelTBM3 | undefined) => {
            return (
                <FieldOrNoData
                    field={
                        item ? (
                            <FormattedTime
                                value={item?.signingState?.timestamp}
                                year="numeric"
                                month="2-digit"
                                day="2-digit"
                            />
                        ) : null
                    }
                />
            );
        },
    },
    {
        id: 'timestamp',
        field: 'timestamp',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.signingDate',
        format: (field: any, item: ApiDeviceSigningDataModelTBM3 | undefined) => (
            <FieldOrNoData
                field={
                    item ? (
                        <FormattedTime
                            value={item?.firstSigningDate}
                            year="numeric"
                            month="2-digit"
                            day="2-digit"
                        />
                    ) : null
                }
            />
        ),
    },
    {
        id: 'signingStatus',
        field: 'signingStatus',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.signingStatus',
        format: (field: any, item: ApiDeviceSigningDataModelTBM3 | undefined) => {
            return <FieldOrNoData field={item?.signingState?.signingStatus} />;
        },
    },
    {
        id: 'errorMessage',
        field: 'errorMessage',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.errorMessage',
        format: (field: any, item: ApiDeviceSigningDataModelTBM3 | undefined) => {
            return <FieldOrNoData field={item?.signingState?.errorStatus} />;
        },
    },
].filter(Boolean);

export const searchDetailsRowDescriptors: ColumnDescriptorProps<SigningSearchResult>[] = [
    {
        id: 'type',
        field: 'type',
        label: 'intl-msg:coreconAdminWeb.type',
        className: 'text-center width-50',
        format: (field: any, item: SigningSearchResult | undefined) => {
            return item?.signing?.deviceType ? (
                <div className="label label-primary label-condensed">
                    {item?.signing?.deviceType}
                </div>
            ) : (
                <NoData />
            );
        },
    },
    {
        id: 'serialNumber',
        field: 'serialNumber',
        label: 'intl-msg:coreconAdminWeb.hwSerial',
        format: (field: any, item: SigningSearchResult | undefined) => [
            <FieldOrNoData
                key="fieldOrNoData"
                field={item?.signing?.serialNumber || item?.monitor?.serialNumber}
            />,
            field && (
                <span
                    key="123"
                    className="rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5"
                    onClick={(event) => {
                        event.stopPropagation();
                        copyToClipboard(field, true);
                    }}
                />
            ),
        ],
    },
    {
        id: 'imei',
        field: 'imei',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.imei',
        format: (field: any, item: SigningSearchResult | undefined) => (
            <FieldOrNoData
                key="fieldOrNoData"
                field={item?.monitor?.imei ? item?.monitor?.imei : item?.signing?.imei}
            />
        ),
    },
    {
        id: 'imsi',
        field: 'imsi',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.imsi',
        format: (field: any, item: SigningSearchResult | undefined) => (
            <FieldOrNoData key="fieldOrNoData" field={item?.signing?.imsi} />
        ),
    },
    {
        id: 'iccid',
        field: 'iccid',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.iccid',
        format: (field: any, item: SigningSearchResult | undefined) => (
            <FieldOrNoData
                key="fieldOrNoData"
                field={item?.monitor?.iccid ? item?.monitor?.iccid : item?.signing?.iccid}
            />
        ),
    },
    {
        id: 'vin7',
        field: 'vin7',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.shortVin',
        format: (field: any, item: SigningSearchResult | undefined) => (
            <FieldOrNoData field={item?.monitor?.vin7} />
        ),
    },
    {
        id: 'vin17',
        field: 'vin17',
        label: 'intl-msg:coreconAdminWeb.deviceSigning.nasysVin',
        format: (field: any, item: SigningSearchResult | undefined) => (
            <FieldOrNoData field={item?.monitor?.vin17} />
        ),
    },
].filter(Boolean);
