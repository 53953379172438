const DEFAULT_LOCALE = 'en-GB';

interface LocaleMap {
    [key: string]: string;
}

// Supported locales map
const supportedLocaleMap: LocaleMap = {
    de: 'de-DE',
    'de-DE': 'de-DE',
    en: 'en-GB',
    'en-GB': 'en-GB',
};

const extractLanguage = (locale: string | null | undefined): string => {
    const safeLocale = locale ?? DEFAULT_LOCALE;
    return safeLocale.split('-')[0];
};


const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

// Replace 'has'
const getSupportedLocale = (preferredLocale: string): string => {
    return preferredLocale in supportedLocaleMap
        ? supportedLocaleMap[preferredLocale]
        : DEFAULT_LOCALE;
};

export { DEFAULT_LANG, DEFAULT_LOCALE, extractLanguage, supportedLocaleMap, getSupportedLocale };