import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../../../configuration/setup/store';
import {DEVICE_TYPE, DeviceStateCommon, DeviceStateMBB, DeviceStateTBM3} from './device-state.types';
import {
    AllDeviceSigningInfo,
    ApiDeviceSigningDataModelTBM3,
    DeviceSigningCertificateInfo
} from './device-signing.types';
import {TAB_NAME} from './SidebarTabs';

export interface CertificateInfoPayload {
    serialNumber: string;
    type: DEVICE_TYPE;
    certificateInfo: DeviceSigningCertificateInfo[] | undefined;
}

export interface CertificatePayload {
    certSerialNumber: string;
    certificate: string;
}

interface SidebarState {
    deviceStates: { [key: string]: DeviceStateTBM3 | DeviceStateMBB };
    deviceSigningStates: { [key: string]: AllDeviceSigningInfo };
    tbmVcmAssociation: { [key: string]: ApiDeviceSigningDataModelTBM3 };
    deviceCertificates: { [key: string]: DeviceSigningCertificateInfo[] };
    certificates: { [key: string]: string }; // Stores the certificate as a string with the certSerialNumber as key
    selectedCertificate: string;
    selectedDevice: string;
    noDataFetched: boolean;
    notifiedForNoData: boolean;
    selectedTab: TAB_NAME;
}

const initialState = {
    deviceStates: {},
    deviceSigningStates: {},
    tbmVcmAssociation: {},
    deviceCertificates: {},
    certificates: {},
    selectedCertificate: '',
    selectedDevice: '',
    noDataFetched: false,
    notifiedForNoData: false,
    selectedTab: TAB_NAME.DETAILS
} as SidebarState;

const KEY_SEPARATOR = '|';

export const genDeviceKey =
    (device: DeviceStateCommon | undefined, type?: DEVICE_TYPE, serialNumber?: string): string => {
        if (device === undefined) {
            if (type === undefined || serialNumber === undefined) {
                return '';
            } else {
                return type + KEY_SEPARATOR + serialNumber;
            }
        } else {
            return device.type + KEY_SEPARATOR + device.serialNumber;
        }
    };

export interface SelectedDeviceSidebar {
    device: DeviceStateCommon;
    context: TAB_NAME | undefined;
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSelectedTab: (state, action: PayloadAction<TAB_NAME | undefined>) => {
            if (action.payload !== undefined) {
                state.selectedTab = action.payload;
            } else {
                state.selectedTab = TAB_NAME.DETAILS;
            }
        },
        setSelectedDeviceSidebar: (state, action: PayloadAction<SelectedDeviceSidebar | undefined>) => {
            if (action.payload?.context !== undefined) {
                state.selectedTab = action.payload?.context;
            }
            state.selectedDevice = genDeviceKey(action.payload?.device);
            state.noDataFetched = false;
            state.notifiedForNoData = false;
        },
        setNoDataFetchedSidebar: (state, action: PayloadAction<boolean>) => {
            state.noDataFetched = action.payload;
        },
        setNotifiedForNoData: (state, action: PayloadAction<boolean>) => {
            state.notifiedForNoData = action.payload;
        },
        setSelectedCertificate: (state, action: PayloadAction<string>) => {
            state.selectedCertificate = action.payload;
        },
        saveDeviceState: (state, action: PayloadAction<DeviceStateCommon | undefined>) => {
            if (action.payload === undefined) {
                return;
            }
            const key = genDeviceKey(action.payload);
            if (action.payload.type === DEVICE_TYPE.OCU3) {
                state.deviceStates[key] = action.payload as DeviceStateMBB;
            } else {
                state.deviceStates[key] = action.payload as DeviceStateTBM3;
            }
        },
        saveSigningInfo: (state, action: PayloadAction<AllDeviceSigningInfo | undefined>) => {
            if (action.payload === undefined || action.payload.signing === undefined) {
                return;
            }
            const key = genDeviceKey(undefined, action.payload.signing.deviceType, action.payload.signing.serialNumber);
            state.deviceSigningStates[key] = {
                ...state.deviceSigningStates[key],
                ...action.payload,
            };
        },
        saveVCMData: (state, action: PayloadAction<ApiDeviceSigningDataModelTBM3 | undefined>) => {
            if (action.payload === undefined) {
                return;
            }
            state.tbmVcmAssociation[state.selectedDevice] = {
                ...state.tbmVcmAssociation[state.selectedDevice],
                ...action.payload,
            };
        },
        saveCertificateInfo: (state, action: PayloadAction<CertificateInfoPayload>) => {
            if (
                action.payload === undefined ||
                action.payload.serialNumber === undefined ||
                action.payload.type === undefined ||
                action.payload.certificateInfo === undefined
            ) {
                return;
            }
            const key = genDeviceKey(undefined, action.payload.type, action.payload.serialNumber);
            state.deviceCertificates[key] = [
                ...(state.deviceCertificates[key] || []),
                ...action.payload.certificateInfo.filter(
                    (cert) => !(state.deviceCertificates[key]?.some((existingCert) => existingCert === cert))
                ),
            ];
        },
        saveCertificate: (state, action: PayloadAction<CertificatePayload>) => {
            if (
                action.payload === undefined ||
                action.payload.certSerialNumber === undefined ||
                action.payload.certificate === undefined
            ) {
                return;
            }
            state.certificates[action.payload.certSerialNumber] = action.payload.certificate;
        },
    },
});

export const {
    setSelectedTab,
    setSelectedDeviceSidebar,
    setNoDataFetchedSidebar,
    setNotifiedForNoData,
    saveDeviceState,
    saveSigningInfo,
    saveCertificateInfo,
    saveVCMData,
    saveCertificate,
    setSelectedCertificate
} = sidebarSlice.actions;

export const getSelectedCertificateSerialNumber = (state: RootState) => state.sidebar.selectedCertificate;

export const getSelectedCertificate = (state: RootState) =>
    state.sidebar.certificates[state.sidebar.selectedCertificate];

export const getSelectedDeviceSidebar = (state: RootState) => state.sidebar.selectedDevice;

export const getNoDataFetchedSidebar = (state: RootState) => state.sidebar.noDataFetched;
export const getNotifiedForNoData = (state: RootState) => state.sidebar.notifiedForNoData;

export const getSelectedDeviceStateSidebar = (state: RootState) =>
    state.sidebar.deviceStates[state.sidebar.selectedDevice];

export const getSelectedDeviceSigningSidebar = (state: RootState) =>
    state.sidebar.deviceSigningStates[state.sidebar.selectedDevice];

export const getSelectedDeviceSigningVCMSidebar = (state: RootState) =>
    state.sidebar.tbmVcmAssociation[state.sidebar.selectedDevice];

export const getSelectedDeviceCertificatesSidebar = (state: RootState) =>
    state.sidebar.deviceCertificates[state.sidebar.selectedDevice];

export const getSelectedTab = (state: RootState) => state.sidebar.selectedTab;

export default sidebarSlice.reducer;