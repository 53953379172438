import React from 'react';
import {FormattedMessage} from 'react-intl';

import clsx from 'clsx';
import {
    DeviceStateCommon,
    DeviceStateMBB,
    DeviceStateTBM3
} from '../../features/app/context/device-sidebar/device-state.types';
import {
    AllDeviceSigningInfo,
    ApiDeviceSigningDataModelTBM3
} from '../../features/app/context/device-sidebar/device-signing.types';
import {ColumnDescriptorProps} from './ListTable';

// table-bordered
const tableClassName = 'table table-layout-fixed table-column-overflow-hidden table-head-filled table-sticky';

interface PropertiesTableProps {
    className: string;
    data: DeviceStateTBM3 | DeviceStateMBB |
        AllDeviceSigningInfo | ApiDeviceSigningDataModelTBM3 |
        DeviceStateCommon | undefined;
    rowDescriptors: ColumnDescriptorProps<any>[];
    showHeader: boolean;
}

export const PropertiesTable = ({className, data, rowDescriptors, showHeader}: PropertiesTableProps) => {
    const renderRows = () => {
        return rowDescriptors.map((rowDescriptor) => renderRow(rowDescriptor));
    };

    const renderRow = (rowDescriptor: ColumnDescriptorProps<any>) => {
        // @ts-ignore
        const value = data[rowDescriptor.field];
        const valueTd = (<td>{rowDescriptor.format ? rowDescriptor.format(value, data) : value}</td>);

        if (rowDescriptor.hidden || (rowDescriptor.toHide && rowDescriptor.toHide(value, data))) {
            return;
        }

        return (
            <tr data-field={rowDescriptor.field} key={rowDescriptor.id}>
                <td className="text-color-dark">
                    {toLabel(rowDescriptor)}
                </td>
                {valueTd}
            </tr>
        );
    };

    const toLabel = (rowDescriptor: any) => {
        const {formatLabel, field, label} = rowDescriptor;
        if (formatLabel) {
            return formatLabel(field);
        }
        if (label) {
            return <FormattedMessage id={label}/>;
        }
        return <FormattedMessage id={`intl-msg:field.unknown.${field}`}/>;
    };

    if (!data) {
        return <FormattedMessage id="intl-msg:nothingFound"/>;
    }
    const rows = renderRows();
    return (
        <table className={clsx(tableClassName, className)}>
            {showHeader ?
                <thead className="table-head">
                <tr>
                    <th className="user-select-none"><FormattedMessage id="intl-msg:field"/></th>
                    <th className="user-select-none"><FormattedMessage id="intl-msg:value"/></th>
                </tr>
                </thead>
                : null
            }
            <tbody>
            {rows}
            </tbody>
        </table>
    );
};
