import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import { config } from '../config';
import { accessToken } from '../configuration/tokenHandling/accessToken';
import { AccessToken } from '../configuration/tokenHandling/tokenSlice';
import { mapDeviceStateMBB, mapDeviceStateMBBList, mapDeviceStateTBM3List } from './deviceStateMapper';
import {
    ApiDeviceStateMBB,
    ApiDeviceStateMBBList,
    ApiDeviceStateTBM3List,
    DeviceStateMBB,
    DeviceStateMBBList,
    DeviceStateTBM3List,
    MbbExternalError
} from '../features/app/context/device-sidebar/device-state.types';
import { DeviceListFilter, DeviceListPage, } from '../features/app/context/device-state/components/DeviceList';

const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

export const SERIAL_NUMBER_LENGTH = 11;
export const VIN_LENGTH = 17;
export const ACCOUNT_OR_DEVICE_ID_LENGTH = 36;

const preparePrefix = (prefixValue: string, length: number, upperCase?: boolean) => {
    const prefixValueTrimmed = prefixValue.trim();
    const prefix = !prefixValueTrimmed ?
        '*'
        : (prefixValueTrimmed.length === length) ?
            prefixValueTrimmed : '*' + prefixValueTrimmed + '*';
    return !!upperCase ? prefix.toUpperCase() : prefix;
};

export const deviceStateTBM3Api = createApi({
    reducerPath: 'deviceStatesTBM3',
    tagTypes: ['DeviceState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_STATE_TBM3_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchDeviceTBM3States: builder.query<DeviceStateTBM3List, {
            filterTBM3: DeviceListFilter;
            page: DeviceListPage;
        }>({
            query: ({filterTBM3, page}) => ({
                url: `/devices?${qs.stringify(
                    {
                        limit: page.limit,
                        cursor: page.cursorTBM3,
                        serial_number:
                            !filterTBM3.serialNumberPrefix?.trim() ?
                                null : preparePrefix(filterTBM3.serialNumberPrefix, SERIAL_NUMBER_LENGTH),
                        vin:
                            !filterTBM3.vinPrefix?.trim() ?
                                null : preparePrefix(filterTBM3.vinPrefix, VIN_LENGTH, true),
                        mileage: filterTBM3.withoutMileage ? 'none' : null,
                        status: !filterTBM3.status?.trim() ? null : filterTBM3.status,
                        accountId:
                            !filterTBM3.accountIdPrefix?.trim() ?
                                null : preparePrefix(filterTBM3.accountIdPrefix, ACCOUNT_OR_DEVICE_ID_LENGTH),
                    },
                    {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
                responseHandler: async (response: Response) => {
                    return response.json();
                },
            }),
            transformResponse: (response: ApiDeviceStateTBM3List) => mapDeviceStateTBM3List(response),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceState'],
        }),
    }),
});

export const deviceStateMBBApi = createApi({
    reducerPath: 'deviceStatesMBB',
    tagTypes: ['DeviceState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_STATE_MBB_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchDeviceMBBStates: builder.query<DeviceStateMBBList, {
            filterMBB: DeviceListFilter;
            page: DeviceListPage;
        }>({
            query: ({filterMBB, page}) => ({
                url: `devices?${qs.stringify(
                    {
                        limit: page.limit,
                        cursor: page.cursorMBB,
                        deviceId: filterMBB.deviceIdPrefix,
                        vin: filterMBB.vinPrefix,
                        status: filterMBB.status,
                        accountId: filterMBB.accountIdPrefix,
                    },
                    {
                        skipNulls: true,
                        encode: false,
                    }
                )}`,
                responseHandler: async (response: Response) => {
                    return response.json();
                },
            }),
            transformResponse: (response: ApiDeviceStateMBBList) => mapDeviceStateMBBList(response),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceState'],
        }),
        fetchDeviceMBBRegistry: builder.query<DeviceStateMBB, {
            vin: string | undefined;
        }>({
            query: ({vin}) => ({
                url: `/${vin}`,
                responseHandler: async (response: Response) => response.json(),
            }),
            transformResponse: (response: ApiDeviceStateMBB) => mapDeviceStateMBB(response),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceState'],
        }),
    }),
});

export const deviceStateMBBRegistryApi = createApi({
    reducerPath: 'deviceStateRegistryMBB',
    tagTypes: ['DeviceState'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DEVICE_STATE_MBB_REGISTRY_SERVICE,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        fetchDeviceMBBRegistryStates: builder.query<DeviceStateMBB, {
            vin: string | undefined;
        }>({
            query: ({vin}) => ({
                url: `/status/${vin}`,
                responseHandler: async (response: Response) => response.json(),
            }),
            transformResponse: (response: ApiDeviceStateMBB) => mapDeviceStateMBB(response),
            keepUnusedDataFor: 0,
            providesTags: ['DeviceState'],
        }),
        vsrCheck: builder.query<MbbExternalError, string>({
            query: (vin) => ({
                url: `/vsr/check/${vin}`
            }),
            transformErrorResponse: (response: { status: string | number }) => response.status
        }),
    }),
});

// Export hooks for usage in function components which are
export const {useFetchDeviceTBM3StatesQuery, useLazyFetchDeviceTBM3StatesQuery} = deviceStateTBM3Api;
export const {useFetchDeviceMBBStatesQuery, useFetchDeviceMBBRegistryQuery} = deviceStateMBBApi;
export const {useFetchDeviceMBBRegistryStatesQuery, useLazyVsrCheckQuery} = deviceStateMBBRegistryApi;
