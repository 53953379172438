import React from 'react';
import {FormattedMessage} from 'react-intl';
import clsx from 'clsx';
import {DEVICE_TYPE} from './device-state.types';

export enum TAB_NAME {
    'DETAILS' = 'Details',
    'SIGNING_DETAILS' = 'SigningDetails',
    'CERTIFICATES' = 'Certificates'
}

interface DeviceStateDetailsTabsProps {
    selectedTabId: TAB_NAME;
    setSelectedTabId: (tabId: TAB_NAME) => void;
    deviceType: DEVICE_TYPE | undefined;
}

export const SidebarTabsComponent = ({selectedTabId, setSelectedTabId, deviceType}: DeviceStateDetailsTabsProps) => {
    const renderNavigationItem = (tabId: TAB_NAME, currentTabId: string, label: string, badgeValue?: string) => {
        const className = {
            active: currentTabId === tabId,
        };
        return (
            <li key={tabId} className={clsx(className)}>
                <a className="nav-link" onClick={() => setSelectedTabId(tabId)}>
                    <FormattedMessage id={label}/>
                    {
                        badgeValue ? <span className="badge margin-left-5">{badgeValue}</span> : null
                    }
                </a>
            </li>
        );
    };

    const renderNavigation = () => {
        return (
            <ul className="nav nav-pills">
                {renderNavigationItem(
                    TAB_NAME.DETAILS, selectedTabId, 'intl-msg:coreconAdminWeb.details')}
                {deviceType !== DEVICE_TYPE.OCU3 && renderNavigationItem(
                    TAB_NAME.SIGNING_DETAILS, selectedTabId, 'intl-msg:coreconAdminWeb.signingDetails')}
                {deviceType !== DEVICE_TYPE.OCU3 && renderNavigationItem(
                    TAB_NAME.CERTIFICATES, selectedTabId, 'intl-msg:coreconAdminWeb.certificates')}
            </ul>
        );
    };

    const navigation = renderNavigation();
    return (
        <div className="device-details-tabs row margin-top-20 margin-bottom-20">
            <div className="col-12">
                {navigation}
            </div>
        </div>
    );
};
