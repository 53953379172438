import Select from '@rio-cloud/rio-uikit/Select';
import Button from '@rio-cloud/rio-uikit/Button';
import {FormattedMessage} from 'react-intl';
import React, {useEffect, useState} from 'react';
import {
    useLazyFetchDeviceCM4Query,
    useLazyFetchDeviceMonitorInfoQuery,
    useLazyFetchDeviceSigningInfoQuery
} from '../../../../../services/deviceSigningStateApi';
import {ApiDeviceSigningDataModelCommon, DeviceMonitor} from '../../device-sidebar/device-signing.types';
import {DefaultWhiteColumn} from '../../../../../components/DefaultWhiteColumn';
import {ListTable} from '../../../../../components/table/ListTable';
import {searchDetailsRowDescriptors} from '../../device-sidebar/data-descriptors/signingDetailsRowDescriptors';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import {useDispatch} from 'react-redux';
import {setSelectedDeviceSidebar} from '../../device-sidebar/sidebarSlice';
import {TAB_NAME} from '../../device-sidebar/SidebarTabs';
import {useAppSelector} from '../../../../../configuration/setup/hooks';
import {clearDevices, genDeviceKey, getSearchResults, KEY_SEPARATOR, saveDeviceInfo} from '../signingSlice';
import Notification from '@rio-cloud/rio-uikit/Notification';
import {DEVICE_TYPE} from '../../device-sidebar/device-state.types';

interface SigningSearchPrefix {
    id: string;
    label: string;
    deviceIdType?: string;
    selected?: boolean;
}

export interface SigningSearchResult {
    serialNumber: string;
    monitor?: DeviceMonitor;
    signing?: ApiDeviceSigningDataModelCommon;
}

const SigningSearchPrefixes: SigningSearchPrefix[] = [
    {id: 'serialnumber', deviceIdType: 'SERIALNUMBER', label: 'Serial Number', selected: true},
    {id: 'imei', deviceIdType: 'IMEI', label: 'IMEI'},
    {id: 'imsi', deviceIdType: 'IMSI', label: 'IMSI'},
    {id: 'iccid', deviceIdType: 'ICCID', label: 'ICCID'},
    {id: 'vin17', label: 'VIN'},
    {id: 'vin7', label: 'Short VIN'},
];

export interface SigningSearchProps {
    onResults: (status: boolean) => void;
}

export const SigningSearch = ({onResults}: SigningSearchProps) => {
    const dispatch = useDispatch();
    const devices = useAppSelector(getSearchResults);
    const [searchPrefix, setSearchPrefix] = useState<SigningSearchPrefix | undefined>(SigningSearchPrefixes[0]);
    const [searchValue, setSearchValue] = useState('');

    const [fetchDeviceMonitorInfo, {
        data: fetchedDeviceFromMonitor,
        isLoading: isLoadingInitMonitorInfo,
        isError: isErrorMonitor,
        isSuccess: isSuccessMonitor
    }] = useLazyFetchDeviceMonitorInfoQuery();

    const [fetchTBM3Device, {
        data: fetchedTBM3Device,
        isLoading: isLoadingTBM3,
        isError: isErrorTBM3,
        isSuccess: isSuccessTBM3
    }] = useLazyFetchDeviceSigningInfoQuery();

    const [fetchCM4Device, {
        data: fetchedCM4Device,
        isLoading: isLoadingCM4,
        isError: isErrorCM4,
        isSuccess: isSuccessCM4
    }] = useLazyFetchDeviceCM4Query();

    // Listens to devices list changes and updates the callback
    useEffect(() => {
        onResults(Object.keys(devices).length > 0);
    }, [devices]);


    // Listens to backend call results and sets the data
    useEffect(() => {
        if (fetchedDeviceFromMonitor !== undefined) {
            dispatch(saveDeviceInfo({
                serialNumber: genDeviceKey(undefined, DEVICE_TYPE.TBM3, fetchedDeviceFromMonitor.serialNumber),
                monitor: fetchedDeviceFromMonitor
            }));
            if (searchPrefix && (searchPrefix.id === 'vin7' || searchPrefix.id === 'vin17')) {
                fetchTBM3Device({serialNumber: fetchedDeviceFromMonitor.serialNumber, identifier: 'SERIALNUMBER'});
            }
        }
        if (fetchedCM4Device !== undefined) {
            dispatch(saveDeviceInfo({
                serialNumber: genDeviceKey(undefined, DEVICE_TYPE.CM4, fetchedCM4Device.serialNumber),
                signing: fetchedCM4Device
            }));
        }
        if (fetchedTBM3Device !== undefined && fetchedTBM3Device.length > 0) {
            dispatch(saveDeviceInfo({
                serialNumber: genDeviceKey(
                    undefined,
                    fetchedTBM3Device[0].deviceType,
                    fetchedTBM3Device[0].serialNumber),
                signing: fetchedTBM3Device[0]
            }));
            if (fetchedTBM3Device.length > 1) {
                dispatch(saveDeviceInfo({
                    serialNumber: genDeviceKey(
                        undefined,
                        fetchedTBM3Device[1].deviceType,
                        fetchedTBM3Device[1].serialNumber),
                    signing: fetchedTBM3Device[1]
                }));
            }
        }
    }, [fetchedDeviceFromMonitor, fetchedCM4Device, fetchedTBM3Device]);

    const fetchDevice = () => {
        dispatch(clearDevices());
        fetchDeviceMonitorInfo({searchPrefix: searchPrefix?.id ?? '', value: searchValue});
        if (searchPrefix?.id !== 'vin7' && searchPrefix?.id !== 'vin17') {
            fetchCM4Device({serialNumber: searchValue, identifier: searchPrefix?.deviceIdType});
            fetchTBM3Device({serialNumber: searchValue, identifier: searchPrefix?.deviceIdType});
        }
    };

    const handleSearchPrefixChange = (selectedOption: SigningSearchPrefix | undefined) => {
        if (selectedOption === undefined) {
            setSearchPrefix(undefined);
        } else {
            setSearchPrefix(selectedOption);
        }
    };

    const handleSearchValue = (value: string) => {
        setSearchValue(value);
    };

    const handleEnterPress = (key: any) => {
        if (key.hasOwnProperty('key') && key.key === 'Enter') {
            fetchDevice();
        }
    };

    const getSearchToolbar = (): JSX.Element => {
        return <div>
            <h6>Device Search</h6>
            <div className="max-width-600">
                <div className='input-group'>
                    <div className='input-group-btn'>
                        <Select options={SigningSearchPrefixes}
                                onChange={handleSearchPrefixChange}
                                value={[searchPrefix?.id ?? '']}/>
                    </div>
                    <ClearableInput className='margin-left--1'
                                    maxLength={30}
                                    value={searchValue}
                                    onChange={handleSearchValue}
                                    onKeyPress={handleEnterPress}/>
                    <div className='input-group-btn'>
                        <Button bsStyle={'primary'} onClick={fetchDevice}>
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.search'}/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>;
    };

    const toggleRow = (rowId: any, isActiveRowId: boolean) => {
        const device = devices.find(value => value.serialNumber === rowId);
        if (isActiveRowId && device && device.signing?.deviceType) {
            const serialNumber = device.serialNumber.split(KEY_SEPARATOR)[1];
            dispatch(setSelectedDeviceSidebar({
                device: {
                    serialNumber,
                    type: device.signing?.deviceType,
                    originator: ''
                },
                context: TAB_NAME.SIGNING_DETAILS
            }));
        } else if (isActiveRowId && device) {
            Notification.warning('Could not get device type of ' + device.serialNumber);
        } else {
            dispatch(setSelectedDeviceSidebar(undefined));
        }
    };

    const isLoading = isLoadingInitMonitorInfo || isLoadingCM4 || isLoadingTBM3;

    const finishedFetching = isErrorMonitor || isSuccessMonitor
        || isErrorTBM3 || isSuccessTBM3
        || isErrorCM4 || isSuccessCM4;
    return (
        <>
            <DefaultWhiteColumn className="padding-10">
                {getSearchToolbar()}
                {finishedFetching && (Object.keys(devices).length === 0) &&
                    <div>
                        <p>No devices found</p>
                    </div>
                }
                {(Object.keys(devices).length > 0) &&
                    <div className={'padding-top-bottom-2em'}>
                        <h6>{'Result'}</h6>
                        <ListTable className="table-bordered table-condensed height-100pct"
                                   items={devices}
                                   highlightSelectedRow
                                   itemKey="serialNumber"
                                   onRowClick={(rowId, isActiveRowId) => toggleRow(rowId, isActiveRowId)}
                                   columnDescriptors={searchDetailsRowDescriptors}
                                   showHeader
                                   isLoading={isLoading}
                        />
                    </div>
                }
            </DefaultWhiteColumn>
        </>
    );
};