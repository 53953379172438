import React from 'react';
import clsx from 'clsx';

interface DefaultWhiteColumnProps {
    className?: string;
    children: any;
}
export const DefaultWhiteColumn = ({className, children}: DefaultWhiteColumnProps) => {
    return (
        <div className='col-12'>
            <div className={clsx('bg-white padding-left-20 padding-right-20', className)}>
                {children}
            </div>
        </div>
    );
};
