import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { LogDeviceWhitelist, LogDeviceWhitelistForm } from './LogDeviceWhitelistForm';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { useIntl } from 'react-intl';
import { useRef } from 'react';
import { useAddMutation, useUpdateMutation } from '../../../../../services/logDeviceWhitelistApi';
import Notification from '@rio-cloud/rio-uikit/Notification';

interface LogDeviceWhitelistFormDialogProps {
    show: boolean;
    edit?: LogDeviceWhitelist;
    onClose: VoidFunction;
}

export const LogDeviceWhitelistFormDialog = ({
                                                 show,
                                                 edit,
                                                 onClose,
                                             }: LogDeviceWhitelistFormDialogProps) => {
    const { formatMessage } = useIntl();
    const formRef = useRef<{ submitForm: () => void } | null>(null);
    const [addLogDeviceWhitelist] = useAddMutation();
    const [updateLogDeviceWhitelist] = useUpdateMutation();

    const onConfirm = () => {
        if (formRef.current) {
            formRef.current.submitForm();
        }
    };

    const handleFormSubmit = async (formData: LogDeviceWhitelist) => {
        try {
            if (!!edit) {
                await updateLogDeviceWhitelist(formData).unwrap();
                Notification.info('Successfully updated');
            } else {
                formData.originator = `${formData.type.toLowerCase()}:${formData.serial_number}`;
                await addLogDeviceWhitelist(formData).unwrap();
                Notification.info('Successfully created');
            }
        } catch (e: unknown) {
            if (typeof e === 'object' && e !== null && 'status' in e && 'data' in e) {
                const error = e as { status: number; data?: { detail?: string } };
                if (error.status === 400) {
                    Notification.warning(error.data?.detail);
                }
                return;
            }
            Notification.error(`Could not ${!!edit ? 'update' : 'create'}`);
        } finally {
            onClose();
        }
    };

    return (
        <ConfirmationDialog
            useOverflow
            show={show}
            bsSize={Dialog.SIZE_XL}
            onClickConfirm={onConfirm}
            onClickCancel={onClose}
            title={formatMessage({ id: 'intl-msg:coreconAdminWeb.logDeviceWhitelist.form' })}
            content={<LogDeviceWhitelistForm ref={formRef} edit={edit} onSubmit={handleFormSubmit} />}
            confirmButtonText={
                <>
                    <span className="rioglyph rioglyph-ok margin-right-5" />
                    <span>{formatMessage({ id: 'intl-msg:coreconAdminWeb.confirm' })}</span>
                </>
            }
            cancelButtonText={formatMessage({ id: 'intl-msg:coreconAdminWeb.cancel' })} />
    );
};
