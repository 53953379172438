import {ActionReducerMapBuilder, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../../configuration/setup/store';
import {deviceStateMBBApi, deviceStateMBBRegistryApi, deviceStateTBM3Api} from '../../services/deviceStateApi';
import {
    DeviceStateCommon,
    DeviceStateMBB,
    DeviceStateMBBList,
    DeviceStateTBM3,
    DeviceStateTBM3List
} from './context/device-sidebar/device-state.types';

import {DeviceSigning} from './context/device-signing/signing.types';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    selectedDeviceState: DeviceStateCommon | undefined;
    selectedDeviceInDeviceSigning: DeviceSigning | undefined;
    devicesTBM3: DeviceStateTBM3List | undefined;
    devicesMBB: DeviceStateMBBList | undefined;
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    selectedDeviceState: undefined,
    selectedDeviceInDeviceSigning: undefined,
    devicesTBM3: undefined,
    devicesMBB: undefined,
};

const unionBy = (array1: any[] = [], array2: any[] = [], key: string) => {
    const map = new Map(array1.concat(array2).map(item => [item[key], item]));
    return Array.from(map.values());
};

const deepMerge = (target: any, source: any): any => {
    Object.keys(source).forEach(key => {
        if (
            source[key] &&
            typeof source[key] === 'object' &&
            !Array.isArray(source[key])
        ) {
            if (!target[key] || typeof target[key] !== 'object') {
                target[key] = {};
            }
            deepMerge(target[key], source[key]);
        } else {
            target[key] = source[key];
        }
    });
    return target;
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
        deviceSelected: (state, action: PayloadAction<DeviceStateCommon | undefined>) => {
            state.selectedDeviceState = action.payload;
        },
        resetDeviceStates: (state) => {
            state.devicesTBM3 = undefined;
            state.devicesMBB = undefined;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<AppState>) => {
        builder.addMatcher(
            deviceStateTBM3Api.endpoints.fetchDeviceTBM3States.matchFulfilled,
            (state, action: PayloadAction<any>) => {
                state.devicesTBM3 = {
                    items: unionBy(state?.devicesTBM3?.items, action.payload?.items, 'originator'),
                    cursorNext: action.payload.cursorNext
                };
            });
        builder.addMatcher(
            deviceStateMBBApi.endpoints.fetchDeviceMBBStates.matchFulfilled,
            (state, action: PayloadAction<any>) => {
                state.devicesMBB = {
                    items: unionBy(state?.devicesMBB?.items, action.payload?.items, 'serialNumber'),
                    cursorNext: action.payload.cursorNext
                };
            });
        builder.addMatcher(
            deviceStateMBBApi.endpoints.fetchDeviceMBBRegistry.matchFulfilled,
            (state, action: PayloadAction<DeviceStateMBB>) => {
                state.selectedDeviceState = deepMerge(state.selectedDeviceState, action.payload);
            });
        builder.addMatcher(
            deviceStateMBBRegistryApi.endpoints.fetchDeviceMBBRegistryStates.matchFulfilled,
            (state, action: PayloadAction<any>) => {
                state.selectedDeviceState = deepMerge(state.selectedDeviceState, action.payload);
            });
    },
});

export const {
    hideSessionExpiredDialog,
    deviceSelected,
    resetDeviceStates
} = appSlice.actions;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;

export const getAllDeviceStates = (state: RootState): (DeviceStateTBM3 | DeviceStateMBB)[] => {
    const allTBM3Items: DeviceStateTBM3[] = state.app.devicesTBM3?.items || [];
    const allMBBItems: DeviceStateMBB[] = state.app.devicesMBB?.items || [];
    return [...allTBM3Items, ...allMBBItems];
};

export const getAllDeviceStatesCommon = (state: RootState): DeviceStateCommon[] => {
    const allTBM3Items: DeviceStateCommon[] =
        state?.app?.devicesTBM3?.items || [];
    const allMBBItems: DeviceStateCommon[] =
        state?.app?.devicesMBB?.items || [];
    return [...allTBM3Items, ...allMBBItems].sort((a, b) =>
        (Number(b.lastOnlineDateTime) || 0) - (Number(a.lastOnlineDateTime) || 0));
};

export const getSelectedDeviceStateId = (state: RootState) => state.app.selectedDeviceState;

export const getSelectedDeviceState = createSelector(getAllDeviceStates, getSelectedDeviceStateId,
    (devices, selectedDeviceStateId) => {
        return devices.find((device) => {
            return device.originator === selectedDeviceStateId?.originator;
        });
    }
);

export default appSlice.reducer;
